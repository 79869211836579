$navbar-color: #fff;
$navbar-hover: #fed700;
$small: 768px;

@font-face {
    font-family: MarckScript;
    src: url(../../assets/fonts/MarckScript.ttf);
}
#hero {
    width: 100%;
    height: 100vh;
}
.navigation-wrapper {
    position: relative;
  }
  .navigation-button {
    will-change: transform;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: fixed;
    z-index: 5;
    top: 10px;
    left: 10px;
    @media only screen and (min-width: $small){
        left:40px;
        top:40px;
    }
    background: transparent;
    cursor: pointer;
    z-index: 52;
    background-color: black;
  width: 80px;
  height: 80px;
  border-radius: 0 50% 50%;
  transition: all 0.5s linear;
  
  }
  .navigation-button .fa {
    border: 5px solid $navbar-color;
    border-radius:50%;
    padding: 18px;
    color: $navbar-color;
    font-size: 24px;
    transition: all 0.5s linear;
    &:hover {
        color:$navbar-hover;
        border: 5px solid $navbar-hover;
    }
  }
  .navigation-button.active {
      transition: all 0.5s linear;
      border-radius: 50% 50%;
      .fa {
      color:$navbar-hover;
        border: 5px solid $navbar-hover;
    }
  }
   
  
  .navigation-menu ul li {
    list-style: none;
    font-family: impact;
    font-weight: 300;
    color: hsl(0,0%,70%);
  }
  
  .navigation-menu {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    width: 85%;
    @media only screen and (min-width: $small) {
        width:60%;
    }
    background: rgb(2,0,36);
    background: linear-gradient(262deg, rgba(2,0,36,1) 0%, rgba(34,34,34,1) 70%, rgba(255,255,255,0) 90%);
    height: 100%;
    transform: skewX(0deg) translate(100%,0);
    transform-origin: top right;
    transition: all .2s ease-in;
    z-index: -1;
  }
  .navigation-menu ul {
    transform: skewX(-8deg);
    transform-origin: top left;
    position: fixed;
    right: 200px;
    top: 10px;
    width: 250px;
    text-align: right;
     @media only screen and (max-width: 992px) {
            right:10px;
            width:80% !important;
    }
  
  }
  .navigation-menu ul li {
    position: relative;
      z-index: 999;
    font-size: 32px;
    color: hsl(0,0%,10%);
    line-height: 64px;
  }
  
  .nav > li > a:focus, .nav > li > a:hover {
      background-color: rgba(0,0,0,0);
  }
  .navigation-menu ul li  a {
    border: none;
    color: #eee;
    text-decoration: none;
    display: block;
    margin-top: 10px;
    cursor: pointer;
      @media only screen and (min-width: $small) {
          margin-top: 35px;
      }
    border-radius: 0 0 10px 10px;
    &:hover {
        color:$navbar-hover;
    }
  }
  
  
  .navigation-menu.active {
    transform: skewX(8deg) translate(0,0);
    z-index: 50;
      
  }
  
  .navigation-menu li {
    opacity: 0;
    transform: translate(0, 10px);
    transition: all .0s ease-in .3s;
    
  }
  .navigation-menu.active li {
    opacity: 1;
    transform: translate(0,0);
    transition: all .3s ease-in 0s;
  }
  .navigation-menu.active li:nth-child(1) {
    transition-delay: .5s;
  }
  .navigation-menu.active li:nth-child(2) {
    transition-delay: .6s;
  }
  .navigation-menu.active li:nth-child(3) {
    transition-delay: .7s;
  }
  .navigation-menu.active li:nth-child(4) {
    transition-delay: .8s;
  }
  .navigation-menu.active li:nth-child(5) {
    transition-delay: .9s;
  }
  .navigation-menu.active li:nth-child(6) {
    transition-delay: 1s;
  }

  #hero {
	width:100%;
	height: 100vh;
	background-color:black;
}
.logo-wrapper {
    width:100%;
    display: flex;
    justify-content:center;
    margin-top:100px;
    position: absolute;
    @media only screen and (min-width: $small) {
        margin-top:100px;
    }
    
}
.page-logo {
    width:250px;
    height:100px;
    @media only screen and (min-width: $small) {
        width:350px;
    }
    
    
    background-size: cover;
    
    position:relative;
    animation: blurIn 1s linear;
    
}
.page-title {
    font-family: MarckScript, cursive;
    font-size: 24px;
    @media only screen and (min-width: $small) {
        font-size: 36px;	
    }
    text-shadow: 5px black;

    text-align: center;
    transform: rotate(-12deg) skew(-16deg);
    color:$navbar-hover;
    background-color:rgba(0,0,0,0.6);
    box-shadow: 1px 2px 5px black;
    padding: 0 10px;
}

.menu-phone {
  color:yellowgreen;
  transition: all 500ms ease-in-out;
  font-size: 32px;
  &:hover {
    color:$rs-yellow;
  }
}
.fa-icon {
  font-family: FontAwesome;
  font-size: 50px;
  font-style: normal;
}
.fa-phone-alt {
  &:before {
    content: '\f879';
  }
}