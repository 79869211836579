@import '../../assets/css/variables.scss';

#preloader {
    transition: all 800ms ease-in-out;
}

.preloader-img {
    width:200px;
    height: 200px;
    @media only screen and (min-width: $small) {
        width: 300px;
        height: 300px;
    }
}