@import '../../assets/css/variables.scss';
@import "~react-image-gallery/styles/scss/image-gallery.scss";
h2.services-title {
    font-family: MarckScript, cursive;
    font-size: 22px;
    @media only screen and (min-width: $small) {
        font-size: 28px;	
    }
}
#gallery {
    padding: 40px 0;
}
.divider {
	width:300px;
	height:75px;
	@media only screen and (min-width: $small) {
	width:600px;
	height:150px;
}
	background-image:url('../../assets/img/divider-vintage.png');
	display: block;
	margin:0 auto 30px;
	background-size:100% 100%;
}