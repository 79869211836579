@import '../../assets/css/variables.scss';

footer {
    background-color: #222;
    font-family: Montserrat;
    padding: 90px 0 0;
    clip-path: polygon(71% 7%,100% 0,100% 100%,0 100%,0 0);
}
footer .footer-box {
    padding:30px 0;
}
footer h3 {
    font-size: 18px;
    color: $rs-yellow;
    text-transform: uppercase;
    margin-bottom: 25px;
    @media only screen and (min-width: $small) {
        font-size:28px;
    }
}
footer ul.footer-items {
    padding: 0;
}
footer ul li{
    list-style-type: none;
    font-size: 16px;
    margin-bottom: 12px;
    color: white;
    padding: 0;
}
footer a {
    text-decoration: none;
    color:white;
    transition: all 500ms ease-in-out;
    &:hover {
        color: $rs-yellow;
        text-decoration: none;
    }
}

footer img {
    width: 30%;
    height: auto;
}
.social-box {
	font-size:38px;
	background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
	color:white;
	width: 60px;
	height: 60px;
	display: block;
	margin: 10px auto 15px;
	border-radius: 50%;
	outline: none;
	i {
		margin-top:12px;
		margin-bottom: 20px;
	}
}

.footer-instagram {
    margin-top: 11px;
    width: 33px;
}

.footer-map {
    max-width: 100%;
    height: auto;
    @media only screen and (min-width: $small) {
        height:300px;
        width:400px;
    }

}

.social-box {
    transition: all 500ms ease-in-out;
    &:hover {
        filter: brightness(70%);
    }
}