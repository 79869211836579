@import '../../assets/css/variables.scss';

.copyright {
    background-color: black;
    padding:10px;
    color: white;   
}
.copyright h6 {
    color:white;
    font-size: 12px;
    @media only screen and (min-width: $small) {
        font-size: 16px;
    }
}