@import '../css/variables.scss';
@import '../../components/About/About.scss';
@import '../../components/Header/Header.scss';
@import '../../components/Services/Services.scss';
@import '../../components/Gallery/Gallery.scss';
@import '../../components/Footer/Footer.scss';
@import '../../components/Copyright/Copyright.scss';
@import '../../components/Preloader/Preloader.scss';

html, body {
    margin:0;
    padding:0;
    scroll-behavior: smooth;
    max-width: 100%;
    overflow-x: hidden;
}

p, li {
   font-family: Montserrat;
   font-size: 16px; 
   letter-spacing: 1px;
   line-height: 1.5em;
}

li {
    list-style: square;
}

h1,h2 {
    margin: 30px 0;
}

h1.about-title {
    font-family: MarckScript, cursive;
    font-size: 36px;
    @media only screen and (min-width: $small) {
        font-size: 48px;	
    }
}
h2.services-title {
    font-family: MarckScript, cursive;
    font-size: 32px;
    @media only screen and (min-width: $small) {
        font-size: 42px;	
    }
}